
@font-face {
  font-family: 'MontserratBold';
  src: local('MontserratBold'), url('./assets/Fonts/MontserratBold.otf');
}

@font-face {
  font-family: 'MontserratLight';
  src: local('MontserratLight'), url('./assets/Fonts/MontserratLight.otf');
}
/* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */

@font-face {
  font-family: 'MontserratMedium';
  src: local('MontserratMedium'), url('./assets/Fonts/MontserratMedium.otf');
}

@font-face {
  font-family: 'MontserratRegular';
  src: local('MontserratRegular'), url('./assets/Fonts/MontserratRegular.otf');
}

@font-face {
  font-family: 'MontserratUltraLight';
  src: local('MontserratUltraLight'), url('./assets/Fonts/MontserratUltraLight.otf');
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #FFFFFF;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #424242;
}
